<template>
    <div>
        <v-container>
          <v-card style="background-color: #f5f5f5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
              Carnet manipulador de alimentos
            </div>

            <v-form ref="form" autocomplete="off">
                <v-row class="px-7 mt-4">
                    <v-col cols="12" sm="6" md="6" lg="4" xl="3">
                        <v-select 
                            dense outlined hide-details
                            v-model="item.documentType"
                            :items="documentTypeOptions"
                            item-value="value"
                            item-text="label"
                            label="Tipo de búsqueda"
                            :menu-props="{ offsetY: true }"
                            @change="item.searchValue = ''">
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6" lg="4" xl="3">
                        <v-text-field
                            class="mb-n7"
                            outlined dense text
                            v-model="item.searchValue"
                            :label="selectedType"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <!-- ACEPTAR / CANCELAR / BORRAR -->
                <v-row class="mt-2" justify="center">
                    <v-btn
                        :loading="loading"
                        :disabled="item.searchValue == ''"
                        @click="downloadFile"
                        color="primary"
                        class="mt-2 mb-4 mr-2"
                        style="width:100px"
                    >
                    Buscar
                    </v-btn>
                </v-row>
            </v-form>
          </v-card>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    components: {},
    computed: {
        ...mapState(['empId', 'urlRaiz']),
        selectedType: function () {
          const type = this.documentTypeOptions.find(f => f.value == this.item.documentType);
          if (type == null) return '';
          return type.label;
        },
    },
    data () {
        return {
            loading: false,
            item: {
                searchValue: '',
                documentType: 1
            },
            documentTypeOptions: [
                //{ label: 'DNI', value: 0 },
                { label: 'Nº empleado', value: 1 }
            ]
        }
    },
    // watch: {
    //   value: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    methods: {
      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;

        return new Promise((resolve) => {
          const controllerParameters = {
            EmpId: this.empId,
            DocumentType: this.item.documentType,
            Search: this.item.searchValue
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/per/get-carnet", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar el carnet");
            }
          });
        })
      },
      //#endregion

    downloadFile(item)
    {
      this.getDataFromApi(item)
        .then(data => {
            if(data.item == null || data.item.File == null) { 
              alert('Documento no encontrado');
              return; 
            }
            this.descargarDocumento(data.item.File, data.item.FileName);
        });
    },
    descargarDocumento(doc, docName){
        this.downloading = true;

        // Datos del fichero
        const contentType = 'application/octet-stream';
        
        // Covertir de base 64 a Blob
        const byteCharacters = atob(doc);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: contentType});

        // Descarga del fichero
        const url = window.URL.createObjectURL(blob);
        var a = document.createElement('a'); 
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = docName; // Nombre del fichero
        a.click();
        a.remove();

        this.downloading = false;
    },
    } 
})
</script>
